import omit from 'lodash.omit';
import axios from 'axios';
// import auth from './auth';
// @ts-ignore
import { getAPINamespace } from '../../shared/lib/api-queue';
// @ts-ignore
import { getAsyncQueue } from '../../shared/utils/async';
import jwt_decode from "jwt-decode";
const coston = process.env.REACT_APP_PUBLIC_API_ROOT;
if (!coston) {
    throw new Error('Public API is not defined');
}
export class TokenManager {
    static auth0;
    static token;
    static setAuth0(auth0) {
        this.auth0 = auth0;
    }
    static setToken(token) {
        this.token = token;
    }
    static getUserIdFromToken() {
        if (!this.token)
            return '';
        try {
            // @ts-ignore
            const { sub } = jwt_decode(this.token);
            return sub;
        }
        catch (err) {
            // @ts-ignore
            window.token = this;
            console.log(`[JWT DECODE ERROR]`, err);
            return '';
        }
    }
    static async getToken() {
        return new Promise(resolve => {
            this.auth0.getAccessTokenSilently().then((token) => {
                this.setToken(token);
                resolve(token);
            });
        });
    }
    static async renewToken() {
        if (isJwtExpired(this.token)) {
            return this.getToken();
        }
    }
    static logout() {
        if (this.auth0) {
            this.auth0.logout({
                // @ts-ignore
                returnTo: window.location.origin
            });
        }
    }
}
var _APICache = {};
export const expireCache = (key) => {
    _APICache = omit(_APICache, key);
};
export const setCache = (key, val) => {
    _APICache[key] = val;
};
export const getFromCache = (key) => _APICache[key];
export const allQueues = {};
const isJwtExpired = (token) => {
    if (!token)
        return true;
    try {
        // @ts-ignore
        const { exp } = jwt_decode(token);
        return Math.floor(Date.now() / 1000) > (exp - 5);
    }
    catch (err) {
        console.log(`[JWT DECODE ERROR]`, err);
        return true;
    }
};
const checkJwtExpiration = async () => {
    const retryCount = 3;
    let i = 0;
    if (isJwtExpired(TokenManager.token)) {
        while (i < retryCount) {
            try {
                await TokenManager.renewToken();
                break;
            }
            catch (err) {
                i++;
            }
        }
        if (i >= retryCount) {
            TokenManager.logout();
            return;
        }
    }
    return;
};
export const APICall = async (requestParams = {}, options = {}) => {
    const { cacheDurationSeconds, cacheKey, allowedStatuses = [] } = options;
    let retryCount = 0;
    const makeRequest = async () => {
        await checkJwtExpiration();
        const { url = "" } = requestParams;
        if (!url.startsWith('http')) {
            requestParams.url = `${coston}${url}`;
        }
        const request = () => axios({
            ...requestParams,
            headers: {
                'Authorization': `Bearer ${TokenManager.token}`,
                ...(requestParams.headers || {})
            },
        });
        try {
            const response = await request();
            return response.data;
        }
        catch (err) {
            if (!err.response) {
                throw err;
            }
            let { status } = err.response;
            let errResponse = err.response.data;
            let { message = '', body = {} } = errResponse;
            if (allowedStatuses.includes(status)) {
                return errResponse;
            }
            // sometimes 502s happen intermittently - retry
            if ([502, 503, 504].indexOf(status) > -1 && retryCount < 3) {
                retryCount += 1;
                return makeRequest();
            }
            throw {
                status,
                ...errResponse
            };
        }
    };
    const data = await makeRequest();
    // cache management
    if (cacheKey) {
        setCache(cacheKey, data);
        if (cacheDurationSeconds) {
            setTimeout(() => expireCache(cacheKey), cacheDurationSeconds * 1000);
        }
    }
    return data;
};
export const enqueueAPICall = (requestParams, options = {}) => {
    return new Promise((resolve, reject) => {
        let { cacheKey, cacheDurationSeconds, prepend, locationId, forceFetch } = options;
        if (!cacheKey) {
            cacheKey = requestParams.method !== 'get' || !cacheDurationSeconds
                ? null
                : JSON.stringify(requestParams);
        }
        // if cache hit, just return cache response
        const cachedResponse = cacheKey ? getFromCache(cacheKey) : null;
        if (!forceFetch && cachedResponse) {
            return resolve(cachedResponse);
        }
        // find queue based on namespace
        const namespace = getAPINamespace(requestParams);
        const queue = getAsyncQueue({ namespace, MAX_NUM_PARALLEL_TASKS: 8 });
        // @ts-ignore
        allQueues[namespace] = queue;
        const fetcher = async function () {
            try {
                const response = await APICall(requestParams, {
                    ...options,
                    cacheKey
                });
                resolve(response);
            }
            catch (err) {
                reject(err);
            }
        };
        fetcher.resolve = resolve;
        fetcher.reject = reject;
        /*
        It's hard to control the order in which requests are queued up, it's all based on when certain components are rendered
        To combat this, we look at locationId in the request url and try to find other requests with the same id and group them together
        */
        const locationMatches = requestParams.url.match(/\/location\/(?:([^\/]+?))\//i); /* eslint-disable-line */
        if ((locationMatches && locationMatches[1]) || locationId) {
            locationId = locationId || locationMatches[1];
            fetcher.locationId = locationId;
            // walk backwards through the requests array and try to find one with the same location id
            for (let i = queue.requests.length - 1; i >= 0; i--) {
                let r = queue.requests[i];
                // found one, insert new request at this index
                if (r.locationId === locationId) {
                    queue.emit('insert', {
                        i,
                        t: fetcher
                    });
                    return;
                }
            }
        }
        // add to queue
        queue.emit(prepend ? 'prepend' : 'add', fetcher);
    });
};
let removed = 0;
export function clearItemsFromAPIQueuesByLocation(locationId) {
    for (const [_, queue] of Object.entries(allQueues)) {
        if (locationId) {
            // @ts-ignore
            for (const task of queue.tasks) {
                if (task.locationId === locationId) {
                    // @ts-ignore
                    queue.cancelTask(task);
                }
            }
        }
    }
}
