import { APICall, enqueueAPICall, TokenManager } from './api-utils';
import { paginationSize } from '../reducers/dashboard';
export const fetchUserState = () => void (0);
export const fetchUserFleets = () => APICall({
    method: 'get',
    url: 'fleet',
});
export const fetchUserByEmailAddress = (emailAddress) => APICall({
    method: 'get',
    url: `user_details/${emailAddress}/email`,
});
export const fetchUser = (userId = TokenManager.getUserIdFromToken()) => APICall({
    method: 'get',
    url: 'user_details/' + userId,
});
export const assignUserLocationByEmail = (locationId, emailAddress) => APICall({
    method: 'get',
    url: `location/${locationId}/assign/${emailAddress}/email`,
});
export const createClaim = (recipient, objectUuid, objectType = "location", installer = TokenManager.getUserIdFromToken()) => {
    const body = {
        recipient,
        object: objectUuid,
        object_type: objectType,
        installer
    };
    return APICall({
        method: 'post',
        url: 'claim',
        data: body
    });
};
export const searchFleet = (fleet, model, params, options = {}) => {
    return void (0);
};
export const getInProcess = (fleet, params) => void (0);
export const searchFleetLocations = ({ fleet = "curb", page = paginationSize, skip = 0, sort = "_id", searchTerm = "" }) => enqueueAPICall({
    method: 'get',
    url: `fleet/${fleet}/location/search/${searchTerm}?page=${page}&skip=${skip}&sort=${sort}&id=true`
}, {
    cacheDurationSeconds: 60 * 0.5
});
export const getFleetLocations = ({ fleet = "curb", page = paginationSize, skip = 0, sort = "_id" }) => enqueueAPICall({
    method: 'get',
    url: `fleet/${fleet}/location?page=${page}&skip=${skip}&sort=${sort}&id=true`
}, {
    cacheDurationSeconds: 60 * 0.5
});
export const getLocation = (locationId) => enqueueAPICall({
    method: 'get',
    url: `location/${locationId}/all`
}, {
    cacheDurationSeconds: 60 * 0.5
});
export const updateLocation = (fleet, locationId, data) => void (0);
export const updateLocationFleet = (fleetId, locationId) => {
    return APICall({
        method: 'GET',
        url: `location/${locationId}/fleet/${fleetId}`
    });
};
export const locationListUsersWithAccess = (locationId, params = {}, options = {}) => enqueueAPICall({
    method: 'get',
    url: `/location/${locationId}/users`,
    params
}, {
    cacheDurationSeconds: 60 * 0.5,
    ...options
});
export const locationListInstallations = (fleet, locationId, params, options = {}) => void (0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/installation`,
//     params
//   },
//   {
//     cacheDurationSeconds: 60 * 0.5,
//     ...options
//   }
// );
export const locationListInstallationsExtended = (fleet, locationId, params, options = {}) => enqueueAPICall({
    method: 'get',
    url: `/location/${locationId}/hubs`,
    params
}, {
    cacheDurationSeconds: 60 * 5,
    ...options
});
export const locationGetTimeseries = (fleet, locationId, params, options = {}) => void (0);
// // enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/timeseries`,
//     params
//   },
//   {
//     cacheDurationSeconds: 60 * 60 * 12,
//     allowedStatuses: [404],
//     ...options
//   }
// );
export const locationGetAggregate = (fleet, locationId, params, options = {}) => void (0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/aggregate`,
//     params
//   },
//   {
//     cacheDurationSeconds: 60 * 60 * 3,
//     ...options
//   }
// );
export const locationGetAggregateToNearestInterval = (fleet, locationId, params) => void (0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/aggregate_to_nearest_interval`,
//     params
//   },
//   {
//     cacheDurationSeconds: 60 * 60 * 3
//   }
// );
export const locationGetCSV = (fleet, locationId, params) => void (0);
// enqueueAPICall({
//   method: 'get',
//   url: `/api-app/${fleet}/location/${locationId}/csv`,
//   params
// });
export const locationGetMetadata = (fleet, locationId, params, options = {}) => void (0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/metadata`,
//     params
//   },
//   {
//     cacheDurationSeconds: 60 * 60,
//     ...options
//   }
// );
export const locationGetRegisters = (fleet, locationId, params) => void (0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/registers`,
//     params
//   }  
// );
export const impersonate = (fleet, userId, data) => void (0);
// APICall(
//   {
//     method: 'post',
//     url: `/api-app/${fleet}/impersonate/${userId}`,
//     data,
//   }
// );
export const removeUserAccess = (fleet, id) => void (0);
// APICall({
//   method: 'delete',
//   url: `/api-app/${fleet}/user_access/${id}`
// });
export const addUserToLocationByEmail = (fleet, locationId, email) => void (0);
// APICall({
//   method: 'post',
//   url: `/api-app/${fleet}/location/${locationId}/user`,
//   data: {
//     email
//   }
// });
export const locationListUserAccesses = (fleet, locationId, params) => void (0);
export const sendClaim = (fleet, locationId, data) => void (0);
// APICall({
//   method: 'post',
//   url: `/api-app/${fleet}/location/${locationId}/send_claim`,
//   data
// });
export const getUserDetailByEmail = (fleet, params) => void (0);
// enqueueAPICall({
//   method: 'get',
//   url: `/api-app/${fleet}/user_detail_by_email`,
//   params
// });
export const updateUserDetail = (fleet, userId, userDetail) => void (0);
// APICall({
//   method: 'patch',
//   url: `/api-app/${fleet}/user_detail/${userId}`,
//   data: userDetail
// })
export const getInstallation = (fleet, hubSerial, params) => void (0);
// // enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/installation/${hubSerial}`,
//     params
//   }
// );
export const updateInstallation = (fleet, hubSerial, data) => void (0);
// APICall(
//   {
//     method: 'put',
//     url: `/api-app/${fleet}/installation/${hubSerial}`,
//     data
//   }
// );
export const getHub = (fleet, hubSerial, params, options) => void (0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/hub/${hubSerial}`,
//     params
//   },
//   {
//     cacheDurationSeconds: 60 * 30,
//     ...options
//   }
// );
export const getHubConnectivity = (fleet, hubSerial, params, options = {}) => void (0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/hub_connectivity/${hubSerial}`,
//     params
//   },
//   {
//     cacheDurationSeconds: 60 * 30,
//     ...options
//   }
// );
export const getEnergyProjectionsForHub = (fleet, hubSerial, params) => void (0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/energy-projections/hub/${hubSerial}`,
//     params
//   }
// );
export const upsertEnergyProjectionsForHub = (fleet, hubSerial, data) => void (0);
// APICall(
//   {
//     method: 'post',
//     url: `/api-app/${fleet}/energy-projections/hub/${hubSerial}`,
//     data
//   }
// );
export const updateAdminUserDetail = (data) => void (0);
// APICall(
//   {
//     method: 'patch',
//     url: '/api-app/user-detail',
//     data
//   }
// );
export const updateAdminUser = (data) => void (0);
// APICall(
//   {
//     method: 'patch',
//     url: '/api-app/protected/admin-user',
//     data
//   }
// );
export const getLocationBilling = (fleet, locationId) => void (0);
// // enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/billing`
//   }
// );
export const updateLocationBilling = (fleet, locationId, data) => void (0);
// APICall(
//   {
//     method: 'put',
//     url: `/api-app/${fleet}/location/${locationId}/billing`,
//     data
//   }
// );
export const getLocationTariff = (fleet, locationId) => void (0);
// // enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/tariff`
//   }
// );
export const listTariffs = (params) => void (0);
// // enqueueAPICall(
//   {
//     method: 'get',
//     url: '/api-app/tariffs',
//     params
//   }
// );
export const updateLocationTariff = (fleet, locationId, masterTariffId) => void (0);
// APICall(
//   {
//     method: 'put',
//     url: `/api-app/${fleet}/location/${locationId}/tariff/${masterTariffId}`
//   }
// );
export const getPermissions = (fleet) => void (0);
// APICall(
//   {
//     method: 'get',
//     url:`/api-app/${fleet}/permissions`
//   }
// )
