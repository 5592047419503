const filterFleets = (fleets) => {
    function cleanNestedStructure(data) {
        const uniqueUuids = new Set();
        function clean(node) {
            if (uniqueUuids.has(node.uuid)) {
                // Skip processing if the UUID is already encountered
                return null;
            }
            uniqueUuids.add(node.uuid);
            // Recursively clean children
            // @ts-ignore
            node.children = node.children.map(clean).filter(Boolean);
            return node;
        }
        return data.map(clean).filter(Boolean);
    }
    const filteredFleets = cleanNestedStructure(fleets);
    return filteredFleets;
};
export default filterFleets;
