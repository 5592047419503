import { createSlice } from '@reduxjs/toolkit';
export const fleetsSlice = createSlice({
    name: 'fleet',
    initialState: {
        fleet: undefined,
        fleets: [],
        error: undefined,
        loading: true,
    },
    reducers: {
        setFleets(state, action) {
            state.fleets = action.payload;
        },
        setFleet: (state, action) => {
            state.fleet = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        }
    }
});
export const { setFleets, setFleet, setError, setLoading } = fleetsSlice.actions;
export default fleetsSlice.reducer;
export const getFleet = (state) => state.fleet.fleet;
export const getFleets = (state) => state.fleet.fleets;
export const getLoading = (state) => state.fleet.loading;
export const getError = (state) => state.fleet.error;
// [{"id":"test_001","cobranding":"91893c7f-96ff-4e98-8c73-ff2589fab95b","uuid":"e5914b80-2b26-4dce-be40-dc80bd9584bf","children":[{"id":"test_001_001","parent_fleet":"e5914b80-2b26-4dce-be40-dc80bd9584bf","cobranding":"91893c7f-96ff-4e98-8c73-ff2589fab95b","uuid":"cac667d9-3094-490c-a253-48e3f94064a8","children":[]},{"id":"test_001_002","parent_fleet":"e5914b80-2b26-4dce-be40-dc80bd9584bf","cobranding":"91893c7f-96ff-4e98-8c73-ff2589fab95b","uuid":"cdbe9d8a-2620-459d-a4f7-7b8cb498077c","children":[]}]},{"id":"test_002","cobranding":"91893c7f-96ff-4e98-8c73-ff2589fab95b","uuid":"9de684aa-e17d-4397-8b14-8062529154a9","children":[]},{"id":"test_003","cobranding":"91893c7f-96ff-4e98-8c73-ff2589fab95b","uuid":"0325db8f-2106-4223-9fb4-e63e55049a31","children":[]}]
export const findFleet = (fleets, id) => {
    return fleets.reduce((acc, cur) => {
        const parseChildren = (fleet) => {
            if (fleet.id === id) {
                acc = fleet;
                return;
            }
            if (fleet.children) {
                fleet.children.forEach((fleet) => {
                    if (!acc)
                        parseChildren(fleet);
                });
            }
        };
        if (acc)
            return acc;
        if (cur.id === id) {
            acc = cur;
            return acc;
        }
        parseChildren(cur);
        return acc;
    }, undefined);
};
export const findFleetByUuid = (fleets, uuid) => {
    return fleets.reduce((acc, cur) => {
        const parseChildren = (fleet) => {
            if (fleet.uuid === uuid) {
                acc = fleet;
                return acc;
            }
            if (fleet.children) {
                fleet.children.forEach((fleet) => {
                    if (!acc)
                        parseChildren(fleet);
                });
            }
        };
        if (acc)
            return acc;
        if (cur.uuid === uuid) {
            acc = cur;
            return acc;
        }
        parseChildren(cur);
        return acc;
    }, undefined);
};
